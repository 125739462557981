<template>
  <div >
    <h5Component v-if="device=='h5'"/>
    <HomeBanner v-if="device=='web'" :theme="newTheme" :key="`homeBanner-${componentKey}`"></HomeBanner>
  <div v-if="device=='web'" :class="newTheme == 'black' ? 'theme-black home-box' : 'theme-white home-box'">
    <!-- @note 彈窗提醒 -->
    <!-- <Popup
      v-if="popopupShow"
      :popupImg="popup_img"
      :popupLink="popup_link"
      :popupTitle="popup_title"
      :popupContent="popup_content"
      :popupIds="popup_ids"
      :popupOriginalTitle="popup_original_title"
    ></Popup> -->
    <ad-dialog />
    <!-- <import-notice /> -->
    <!-- 复杂的 banner 区域 -->
    <!-- <notice></notice> -->
    <!--重要通知-->
    
    <popular-activity />
    <!-- 轮播广告 -->
    <div class="home-swiper" v-if="showBanner">
      <Banner :bannerImg="banner_img" :bannerLink="banner_link" :bannerTitle="banner_title"></Banner>
    </div>
    <!-- 公告 -->
    <div class="home-notice" v-if="showNotice">
      <div class="home-notice-item clearfix">
        <a>
          <img v-if="newTheme == 'black'" class="notice-icon" src="~assets/img/new_index/icon_laba.png" />
          <img v-if="newTheme == 'white'" class="notice-icon" src="~assets/img/new_index/icon_laba_white.png" />
        </a>
        <div class="marquee_box">
          <ul class="marquee_list" :class="{ marquee_top: noticeAnimate }">
            <li v-for="(item, index) in noticeList" :key="index">
              <a class="title" :title="item.title" :href="item.noticeUrl" target="_blank">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="noticeList.length" class="notice-more">
        <a :href="$t('links.notice_link')" class="more-link" target="_blank">{{ $t('index.notice_more') }}</a>
        <div class="iconfont icon-more"></div>
      </div>
    </div>

    <!-- 漲跌幅 -->
    <section class="home-screen-item">
      <marketTrend></marketTrend>
    </section>

    <!-- 官方数据 -->
    <section class="home-screen-item official-data-wrap" id="officialRef">
      <div class="official-data-mod" :class="newTheme">
        <div class="official-data-main">
          <img v-if="newTheme === 'black'" class="official-img-wrap" v-lazy="dataBlack" :key="dataBlack" alt="" />
          <img v-if="newTheme === 'white'" class="official-img-wrap" v-lazy="dataWhite" :key="dataWhite" alt="" />
          <div class="official-main">
            <div class="item">
              <div class="title">{{ setCommas(officialNum1) }}+</div>
              <div class="desc">{{ $t('new_index.official-data.cont1.sub-tit') }}</div>
            </div>
            <div class="item">
              <div class="title">{{ setCommas(officialNum2) }}+</div>
              <div class="desc">{{ $t('new_index.official-data.cont2.sub-tit') }}</div>
            </div>
            <a class="item item-active" rel="nofollow"target="_blank"
              href="https://explorer.btc.com/zh-CN/btc/address/bc1qufzpe776gv25v3ejv0a8vk07vhqpelql9jl0cv">
              <div class="title">{{ $t('new_index.official-data.cont3.tit') }}</div>
              <div class="desc">{{ $t('new_index.official-data.cont3.sub-tit') }}
                <div class="right-arrow show-right-arrow"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!-- 公司优点 仅中英文首页使用 -->
    <!-- <section class="home-screen-item advantage" v-if="getLang === 'zh-CN' || getLang === 'en'"> -->
    <section class="home-screen-item advantage">
      <advantage></advantage>
    </section>
    <!-- 跟单模块 -->
    <section>
      <documentary></documentary>
    </section>

    <!-- 下载入口 -->
    <section class="home-screen-item home-down-app">
      <down-info></down-info>
    </section>

    <!-- 合作企業 -->
    <section class="home-screen-item" :class="newTheme">
      <jointVenture ></jointVenture>
    </section>

    <!-- 注册入口 -->
    <section v-if="!isLogin" class="home-screen-item reg">
      <quick-register :theme="newTheme" :key="`quickRegister-${componentKey}`"></quick-register>
    </section>

    <!-- 福利中心入口 -->
    <!-- <welfare-fixed></welfare-fixed> -->
    <!-- 跟單按鈕 -->
    <!-- <div class="copy-trade" @click="copyTrade($t('new_index.copy_trade_link'))"></div> -->
    <!-- 置頂按鈕 -->
    <!-- <div class="icon-go-top" @click="goTop()"></div> -->
    <float-activity />
  </div>
  </div>
</template>

<script>
import { firebaseApp } from '~/plugins/firebase.js';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { mapGetters, mapState, mapActions } from 'vuex';
import { mixLink } from 'utils';
import { addCommas } from '~/utils/globalUtils';
import { throttle } from 'lodash'
import { topBanner, allExchange, indexHomeQuotation, indexUserSelectedList, deleteUserSelf, addUserSelf, getPopUpData, } from '../../server/common/index.js';
import { PC_TradePairClick } from "../../utils/sensorsEvent";
import AdDialog from '~/components/adDialog/AdDialog.vue'
import PopularActivity from '~/components/popularActivity/PopularActivity.vue'
import HomeBanner from "~/components/index/HomeBanner.vue";
import Banner from "~/components/index/Banner.vue";
import h5Component from "~/components/indexH5/index.vue";
import config from "~/config/bannerConfig.json";
import FloatActivity from '~/components/floatActivity/FloatActivity.vue'
import { isClient } from "~/utils/envHelper";
import { loadZendeskByLanguage } from '~/pages/_lang/support/utils/common.js';

export default {
  head() {
    return {
      title: this.$t('index.seo_title'),
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$i18n.t('common.keywords')
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('common.description')
        }
      ]
    };
  },
  computed: {
    ...mapState({
      contractSwitch: state => state.contractAndFollowSwitch.contractSwitch,
      followSwitch: state => state.contractAndFollowSwitch.followSwitch,
    }),
    ...mapState(['newTheme', 'loginHistoryArr', 'locale']),
    ...mapGetters(['isLogin', 'isCN', 'getLang']),
    moneyType() {
      return this.$store.state.moneyType;
    }
  },
  components: {
    'ad-dialog': AdDialog,
    'download-qr': () => import('~/components/common/DownloadQr.vue'),
    marketTrend: () => import('~/components/index/marketTrend'),
    advantage: () => import('~/components/index/advantage'),
    Banner,
    capitalSupport: () => import('~/components/index/capitalSupport'),
    documentary: () => import('~/components/index/documentary'),
    downInfo: () => import('~/components/index/downInfo'),
    HomeBanner,
    HomeTutorial: () => import('~/components/index/HomeTutorial'),
    otcCoin: () => import('~/components/index/otcCoin'),
    OverseaAdvantage: () => import('~/components/index/OverseaAdvantage'),
    Popup: () => import('~/components/Popup'),
    quickRegister: () => import('~/components/index/quickRegisterBlock'),
    welfareFixed: () => import('~/components/index/welfareFixed'),
    'popular-activity':PopularActivity,
    'float-activity':FloatActivity,
    jointVenture: () => import('~/components/index/jointVenture'),
    h5Component
  },
  data() {
    return {
      device: 'web',
      officialNum1: 1000000000,
      officialNum2: 900000,
      popopupShow: false, //預設顯示彈窗
      intervalID: 0, // 轮询标识
      bannerList: [], // 集体轮播广告
      shortBannerList: [], // 单个轮
      noticeList: [], // 公告列表
      inverseList: [], // 反向合约
      contractTabIndex: 1, // 合约切换
      loginName: '',
      showSwiper: false,
      playSwiper: false,
      swiperPageShow: false,
      swiperOption: {
        init: false,
        spaceBetween: 24,
        slidesPerView: 1.2,
        noSwiping: true,
        effect: 'slide',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        loopAdditionalSlides: 2,
        loopFillGroupWithBlank: true,
        speed: 1500,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      swiperOptionLong: {
        init: false,
        spaceBetween: 24,
        noSwiping: true,
        effect: 'slide',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        watchOverflow: true, // 当只有1个时，不会轮播, loop 模式下无效
        loopFillGroupWithBlank: true,
        speed: 1500,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000
        },
        pagination: {
          el: '.swiper-paginationlong',
          clickable: true
        }
      },
      // 为了适配
      mobileSwiperOptionLong: {
        init: false,
        spaceBetween: 24,
        noSwiping: true,
        effect: 'slide',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        watchOverflow: true, // 当只有1个时，不会轮播, loop 模式下无效
        loopFillGroupWithBlank: true,
        speed: 1500,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000
        },
        pagination: {
          el: '.mobile-swiper-paginationlong',
          clickable: true
        }
      },
      bannerContent: null,
      // 首页市场改造新增字段
      userSelectedList: [], // 自选
      userSelectedTabName: '',
      regCode: '',
      video1: {
        src: 'https://media.weex.com/weex-xuetang1.mp4',
        img: '/assets/images/new_index/v1.png',
        playing: false
      },
      video2: {
        src: 'https://media.weex.com/weex-xuetang2.mp4',
        img: '/assets/images/new_index/v2.png',
        playing: false
      },
      video3: {
        src: 'https://media.weex.com/weex_copyTradeTutorial.mp4',
        img: '/assets/images/new_index/v4.png',
        playing: false
      },
      banner_img: [],
      banner_link: [],
      banner_title: [],
      popup_img: [],
      popup_link: [],
      popup_title: [],
      popup_ids: [],
      popup_original_title: [],
      popup_content: [],
      showBanner: true,
      showNotice: true,
      indexDataTab: 2, // 官方數據tab: 0-日均交易量, 1-平台用户数, 2-平台用户数
      noticeAnimate: false,
      editZendeskInterval: null,
      isListeningZendesk: false,
      dataBlack: require('@/assets/img/new_index/img_weex_data_black.png'),
      dataWhite: require('@/assets/img/new_index/img_weex_data_white.png'),
      noticeTimeout: null,
      downloadTimeout: null,
      showMarqueInterval: null,
      componentKey: 1,
      screenHeight: 0,
      throttleSetOfficiaNum: null,
      throttleObj: null,
      chatTimer: null,
      chatConnectCount:0
    };
  },
  mounted() {
    this.switchWebH5();
    this.throttleObj = throttle(this.switchWebH5, 100);
    this.analytics = getAnalytics(firebaseApp);
    this.screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    window.isLoadPromise?.then(() => {
      hideGlobalLoading();
    })
    window.isReadyPromise?.then(() => {
      hideGlobalLoading();
      /** footer點擊下載移動到下載區塊 */
      if (location.href.indexOf('fromFooterAppDownload=Y') != -1) {
        this.downloadTimeout = setTimeout(_ => {
          this.goDownload();
        }, 200);
      }
      this.getNoticesAndBanner();
      this.initGlobalPopup();
    });
    // 赋值以供取消事件调用
    this.throttleSetOfficiaNum = throttle(this.setOfficiaNum, 100);
    window.addEventListener("scroll", this.throttleSetOfficiaNum);
    window.addEventListener('resize',this.throttleObj);
    if(isClient){
        window.onload = ()=>{
          this.setChatLanguage();
        }
    }


  },
  beforeDestroy() {
    this.chatTimer && clearTimeout(this.chatTimer);
    if (this.editZendeskInterval) clearInterval(this.editZendeskInterval);
    if (this.noticeTimeout) clearTimeout(this.noticeTimeout);
    if (this.downloadTimeout) clearTimeout(this.downloadTimeout);
    if (this.intervalID) clearInterval(this.intervalID);
    if (this.showMarqueInterval) clearInterval(this.showMarqueInterval);
    this.$children.forEach(child => {
      child.$destroy()
      child = null
    })
    this.$children.length = 0
    // 移除滚动事件监听
    if (this.throttleSetOfficiaNum) window.removeEventListener("scroll", this.throttleSetOfficiaNum);
    if (this.throttleObj) window.removeEventListener('resize', this.throttleObj);
    window.onresize = null;
    document.getElementsByTagName('html')[0].style['font-size'] = '14px';
  },
  methods: {
    switchWebH5(){
      if (document.body.clientWidth < 768) {
        this.device = 'h5'
        document.getElementsByTagName('html')[0].style['font-size'] = '2.6666666667vw';
      }else{
        this.device = 'web'
        document.getElementsByTagName('html')[0].style['font-size'] = '14px';
      }
    },
    /**
     * 判断并开启弹框提醒 - 每天一次
     * date 代表日期
     * _1 代表登录不登录都弹的弹框已弹
     * _2 代表登录状态要弹的弹框已弹
     * _3 代表不登录状态要弹的弹框已弹
     */
    go2AppStore() {
      let href =
        'https://apps.apple.com/us/app/weex%E5%94%AF%E5%AE%A2-%E6%95%B0%E5%AD%97%E8%B4%A7%E5%B8%81%E5%90%88%E7%BA%A6%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0-btc-eth/id1609350789';
      window.open(href);
    },


    // 设置首页官方模块动态数据
    setOfficiaNum() {
      if(!document.getElementById('officialRef')){
        return
      }
      var rect = document.getElementById('officialRef').getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= this.screenHeight) {
        window.removeEventListener("scroll", this.throttleSetOfficiaNum);
        let timer1 = setInterval(() => {
          let officialNum1 = this.officialNum1;
          officialNum1 += 10500050;
          if (officialNum1 > 1500000000) {
            officialNum1 = 1500000000;
            clearInterval(timer1);
          }
          this.officialNum1 = officialNum1;
        }, 50)
        let timer2 = setInterval(() => {
          let officialNum2 = this.officialNum2;
          officialNum2 += 15000;
          if (officialNum2 > 2000000) {
            officialNum2 = 2000000;
            clearInterval(timer2);
          }
          this.officialNum2 = officialNum2;
        }, 50)
      }
    },
    setCommas(num) {
      return addCommas(num)
    },
    go2GooglePlay() {
      let href = 'https://play.google.com/store/apps/details?id=com.wake.weexprd';
      window.open(href);
    },
    initGlobalPopup() {
      let date = new Date().toLocaleDateString();
      let localData = localStorage.getItem('global-popup-index');
      if (
        !(
          localData &&
          localData.indexOf(date) > -1 &&
          localData.indexOf('_1') > -1 &&
          localData.indexOf('_2') > -1 &&
          localData.indexOf('_3') > -1
        )
      )
        this.showPopUp(date, localData);
    },
    //不同币对展示 不同的符号
    getShowCode(code) {
      if (!code) {
        return '$';
      }
      if (code === 'BTC') {
        return '฿';
      }
      if (code === 'ETH') {
        return 'E';
      }
      if (code.indexOf('USDT') > -1) {
        return '₮';
      }
      return '$';
    },
    gotoCopyTrade() {
      window.location.href =
        'https://weexsupport.zendesk.com/hc/zh-cn/articles/7714823960089-%E4%BA%A4%E6%98%93%E5%91[…]95%E8%BF%9B%E8%A1%8C%E5%B8%A6%E5%8D%95%E4%BA%A4%E6%98%93-';
    },
    noticeDetail(url) {
      window.open(url);
    },
    /**
     * 滚动播放 公告信息
     */
    showMarquee() {
      this.noticeAnimate = true;
      this.noticeTimeout && clearTimeout(this.noticeTimeout);
      this.noticeTimeout = setTimeout(() => {
        this.noticeList.push(this.noticeList[0]);
        this.noticeList.shift();
        this.noticeAnimate = false;
      }, 500);
    },
    getNoticesAndBanner() {
      topBanner()
        .then(res => {
          if (+res.code == 0) {
            if (res.data.noticeList) {
              this.noticeList = res.data.noticeList || [];
              if (this.noticeList.length > 0) {
                this.showMarqueInterval && clearInterval(this.showMarqueInterval);
                this.showMarqueInterval = setInterval(this.showMarquee, 3000)
              } else {
                this.showNotice = false;
              }
            }

            if (res.data.bannerList && res.data.bannerList.length > 0) {
              let _this = this;
              res.data.bannerList.forEach(function (item, index, array) {
                _this.$set(_this.banner_img, index, item.imgUrl);
                _this.banner_link[index] = item.jumpUrl;
                _this.banner_title[index] = item.title;
              });

              // let temp = [];
              // this.shortBannerList = res.data.bannerList;
              // res.data.bannerList.forEach((item, index) => {
              //   if (temp.length === 3) {
              //     _this.bannerList.push(temp);
              //     temp = [];
              //   }
              //   temp.push(item);
              // });
              // this.bannerList.push(temp);
            }else {
              this.showBanner = false;
            }
          } else {
            this.showBanner = false;
            if (res.msg) {
              BT.util.info(res.msg);
            }
          }
          //this.swiperInit();
        })
        .catch(() => {
          //500或者请求超时 对swiper 都进行初始化
          //this.swiperInit();
        });
    },
    // @note 根据条件开启弹框提醒
    showPopUp(date, localData) {
      getPopUpData().then(res => {
        if (res.code == '00000' && res.data.length > 0) {
          // TODO 模拟数据
          // res.data = [
          //   {
          //     position: 1,
          //     status: 'ENABLE',
          //     // picUrl: 'https://weexstg.com/assets/images/school/video3.jpg',
          //     picUrl: 'https://d2lg3695n9b5ww.cloudfront.net/files/notice/87e02580f160de857b677b3ae03ffa91.jpeg',
          //     loginStatus: 1,
          //     languageType: 1,
          //     tipType: 1,
          //     forward: 'www.baidu.com',
          //     title: '无论登录与否都弹 - 图片',
          //     content: '<h1>登录不登录都会弹出的图片</h1>',
          //   },
          //   {
          //     position: 1,
          //     status: 'ENABLE',
          //     picUrl: 'https://weexstg.com/assets/images/school/video3.jpg',
          //     loginStatus: 2,
          //     languageType: 1,
          //     tipType: 2,
          //     forward: 'www.baidu.com',
          //     title: '登录弹 - 富文本',
          //     content: '<h1>登录才会弹出的富文本</h1>',
          //   },
          //   {
          //     position: 1,
          //     status: 'ENABLE',
          //     picUrl: 'https://weexstg.com/assets/images/school/video3.jpg',
          //     loginStatus: 3,
          //     languageType: 1,
          //     tipType: 2,
          //     forward: 'www.baidu.com',
          //     title: '不登录弹 - 富文本',
          //     content: '<h1>不登录才会弹出的富文本不登录才会弹出的富文本不登录才会弹出的富文本不登录才会弹出的富文本不登录才会弹出的富文本</h1><p>我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上我们的家乡在希望的田野上</p>',
          //   },
          // ];

          // 取出需要显示在首页的
          let list = res.data.filter(tmp => tmp.position == '1');
          // 取出非禁用状态的
          list = list.filter(tmp => tmp.status == 'ENABLE');
          // 根据用户是否登录，过滤获取对应的弹框信息
          if (!localData) localData = date;
          else if (localData.indexOf(date) < 0) localData = date;
          if (this.isLogin) {
            // 登录状态先排除非登录弹框
            list = list.filter(tmp => tmp.loginStatus != '3');
            // 登录非登录都弹的弹框 如果已经弹过，则排除
            if (localData.indexOf('_1') > -1) list = list.filter(tmp => tmp.loginStatus != '1');
            else localData += '_1';
            // 登录状态要弹的弹框 如果已经弹过，则排除
            if (localData.indexOf('_2') > -1) list = list.filter(tmp => tmp.loginStatus != '2');
            else localData += '_2';
          } else {
            // 非登录状态先排除登录弹框
            list = list.filter(tmp => tmp.loginStatus != '2');
            // 登录非登录都弹的弹框 如果已经弹过，则排除
            if (localData.indexOf('_1') > -1) list = list.filter(tmp => tmp.loginStatus != '1');
            else localData += '_1';
            // 非登录状态要弹的弹框 如果已经弹过，则排除
            if (localData.indexOf('_3') > -1) list = list.filter(tmp => tmp.loginStatus != '3');
            else localData += '_3';
          }
          // 过滤语系
          if (this.getLang == 'zh-CN') {
            list = list.filter(tmp => tmp.languageType == '1');
          } else if (this.getLang == 'zh-TW') {
            list = list.filter(tmp => tmp.languageType == '2');
          } else if (this.getLang == 'en') {
            list = list.filter(tmp => tmp.languageType == '3');
          } else if (this.getLang == 'ko') {
            list = list.filter(tmp => tmp.languageType == '4');
          } else if (this.getLang == 'ja') {
            list = list.filter(tmp => tmp.languageType == '5');
          }
          // TODO 越南语使用英文兜底  若越南语后续需要自己独有支持，解开下面3行屏蔽，注意languageType需要与接口沟通取值
          // else if (this.getLang == 'vi') {
          //   list = list.filter(tmp => tmp.languageType == '6 ？？');
          // }
          else { //英文兜底
            list = list.filter(tmp => tmp.languageType == '3');
          }
          // 整理弹框数据
          if (list && list.length > 0) {
            list.forEach((item, index) => {
              this.popup_img[index] = item.picUrl; //图片
              this.popup_link[index] = item.forward; //跳转URL
              this.popup_title[index] = item.tipType == '2' ? item.title : ''; //标题
              this.popup_content[index] = item.tipType == '2' ? item.content : ''; //富文本内容
              this.popup_ids[index] = item.id; //富文本内容
              this.popup_original_title[index] = item.title; //富文本内容
            });
            // 显示弹框
            this.popopupShow = true;
            // 设置今天已经弹过
            localStorage.setItem('global-popup-index', localData);
          }
        } else if (res.code != '00000') {
          BT.util.info(res.msg || 'error...');
          console.log('getPopUpData error');
        }
      });
    },
    swiperInit() {
      this.$nextTick(() => {
        this.mySwiper && this.mySwiper.init();
        this.mySwiperLong && this.mySwiperLong.init();
        this.mySwiperMobile && this.mySwiperMobile.init();
      });
    },
    // 设置轮播图
    setShowSwiper() {
      this.showSwiper = !this.showSwiper;
      if (this.showSwiper) {
        this.$nextTick(() => {
          this.mySwiperLong && this.mySwiperLong.autoplay.stop();
          this.mySwiperLong && this.mySwiperLong.autoplay.start();
          this.mySwiper && this.mySwiper.autoplay.stop();
        });
      } else {
        this.$nextTick(() => {
          this.mySwiper && this.mySwiper.autoplay.start();
          this.mySwiperLong && this.mySwiperLong.autoplay.stop();
        });
      }
    },

    // 轮播 鼠标移入
    mousemoveHandle() {
      if (this.shortBannerList.length === 0) {
        return;
      }
      if (this.showSwiper) {
        this.mySwiperLong.autoplay.stop();
      } else {
        this.mySwiper.autoplay.stop();
      }
    },

    // 轮播 鼠标移出
    mouseoutHandle() {
      if (this.shortBannerList.length === 0) {
        return;
      }
      if (this.showSwiper) {
        this.mySwiperLong.autoplay.start();
      } else {
        this.mySwiper.autoplay.start();
      }
    },

    //格式化24
    format24HTradingVolume(item) {
      return '$' + (item / 100000000).toFixed(3);
    },
    goTo(path) {
      this.$router.push(this.$i18n.path(path));
    },
    // 充币
    goCharge() {
      this.$router.push(this.$i18n.path('asset/recharge'));
    },
    // 去注册
    goRegister() {
      if (this.isLogin) {
        this.$router.push(this.$i18n.path('swap'));
        return;
      }
      if (this.regCode) {
        this.$router.push({ path: this.$i18n.path('register'), query: { rname: this.regCode } });
      } else {
        this.$router.push(this.$i18n.path('register'));
      }
    },
    jumpto(path) {
      window.open(path);
    },
    copyTrade(path) {
      logEvent(this.analytics, 'goCopyTradeZendesk_event', {
        platform: 'web'
      });
      window.open(path);
    },
    goTop() {
      logEvent(this.analytics, 'goTop_event', {
        platform: 'web'
      });
      document.querySelector('.main-weex').scrollIntoView();
    },
    goDownload() {
      document.querySelector('.home-down-app').scrollIntoView();
    },
    videoEnter(e) {
      switch (e) {
        case 1:
          this.video1.img = '/assets/images/new_index/v1.png';
          break;
        case 2:
          this.video2.img = '/assets/images/new_index/v2.png';
          break;
        case 3:
          this.video3.img = '/assets/images/new_index/v4.png';
          break;
      }
    },
    videoLeave(e) {
      switch (e) {
        case 1:
          this.video1.img = '/assets/images/new_index/v1.png';
          break;
        case 2:
          this.video2.img = '/assets/images/new_index/v2.png';
          break;
        case 3:
          this.video3.img = '/assets/images/new_index/v4.png';
          break;
      }
    },
    dataChange(val) {
      if (val == 0) {
        //go left
        this.indexDataTab--;
        if (this.indexDataTab == -1) {
          this.indexDataTab = 2;
        }
      } else {
        //go right
        this.indexDataTab++;
        if (this.indexDataTab == 3) {
          this.indexDataTab = 0;
        }
      }
    },
    playVideo(id) {
      let v1 = document.getElementById('video1');
      let v2 = document.getElementById('video2');
      let v3 = document.getElementById('video3');
      switch (id) {
        case 'video1':
          if (this.video1.playing) {
            this.video1.playing = false;
            v1.pause();
          } else {
            this.video1.playing = true;
            v1.play();
            v2.pause();
            v3.pause();
            this.video2.playing = false;
            this.video3.playing = false;
          }
          break;
        case 'video2':
          if (this.video2.playing) {
            this.video2.playing = false;
            v2.pause();
          } else {
            this.video2.playing = true;
            v2.play();
            v1.pause();
            v3.pause();
            this.video1.playing = false;
            this.video3.playing = false;
          }
          break;
        case 'video3':
          if (this.video3.playing) {
            this.video3.playing = false;
            v3.pause();
          } else {
            this.video3.playing = true;
            v3.play();
            v1.pause();
            v2.pause();
            this.video1.playing = false;
            this.video2.playing = false;
          }
          break;
      }
    },
    setChatLanguage(){
      this.chatTimer && clearTimeout(this.chatTimer);
      this.chatConnectCount += 1;
      if(this.chatConnectCount > 5){
        return;
      }
      if(this.locale){
        loadZendeskByLanguage(this.locale)
        return;
      }
     this.chatTimer = setTimeout(() => {
        this.setChatLanguage();
      }, 2000);
    }
  },
  watch: {
    isLogin(val) {
      if (!val) {
        this.getNoticesAndBanner();
      }
    },
    newTheme(val) {
      this.componentKey++;
    },
    locale(val){
      // ko  en vi zh-CN zh-TW
      // this.switchChatLanguage(val)
      this.setChatLanguage();
    }
  }
};
</script>

<style lang="less" scoped>
.show-right-arrow {
  display:block !important;
  width: auto !important;
  margin-left: 0 !important;
}
</style>
<style lang="less">
.home-box {
  &.theme-white {
    .official-data-wrap .official-data-mod .official-data-main .official-main .item-active {
      color: #000000;
    }

    .official-data-wrap .official-data-mod .official-data-main .official-main .item-active .right-arrow {
      background-image: url('~assets/img/common/right-arrow-black.png');

    }
  }

  .white {
    &.zh-carousel {
      .zh-main-wrap {

        .left-wrap .banner-title,
        .qr.download .tit,
        .left-wrap .banner-desc,
        .left-wrap .join-activity {
          color: #000000;
        }
      }

      ::v-deep.el-carousel {
        .el-carousel__indicator {
          .el-carousel__button {
            background: @white-input-color;
          }

          &.is-active {
            .el-carousel__button {
              width: 20px;
              background: @yellow-P3;
            }
          }
        }
      }

      .zh-main-wrap .qr {
        background: @white-popup-bg;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .zh-carousel .zh-main-wrap .left-arrow {
      left: -60px;
    }

    .zh-carousel .zh-main-wrap .right-arrow {
      right: -60px;
    }
  }

  @media screen and (max-width: 1200px) {
    .zh-carousel .zh-main-wrap .left-arrow {
      left: 20px;
    }

    .zh-carousel .zh-main-wrap .right-arrow {
      right: 20px;
    }
  }

  @media screen and (min-width: 1600px) {
    .zh-carousel .zh-main-wrap .left-arrow {
      left: -170px;
    }

    .zh-carousel .zh-main-wrap .right-arrow {
      right: -170px;
    }
  }

  .home-activity {
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #eeeeee;
    font-size: 14px;
    //font-family: PingFangSC-Regular, PingFang SC;
    width: 100%;
    position: relative;

    .activity-close-icon {
      position: absolute;
      top: 0;
      right: 28px;
      cursor: pointer;
    }

    .detail-btn {
      margin-left: 16px;
      cursor: pointer;
    }
  }

  // banner
  .home-adv-box {
    display: block;
    height: 462px;
    padding: 50px 0 50px;
    overflow: hidden;
    //background: #1E1E1E url(~assets/img/new_index/bg_light.png) center center no-repeat;
    background-size: cover;
    text-align: center;
    min-width: 1280px;

    //border: 1px solid red;
    &.en {
      padding-bottom: 30px;
    }

    .home-adv-wrap {
      width: 1200px;
      text-align: center;
      margin: 0 auto;
      position: relative;

      // border: 1px solid sandybrown;
      .home-adv-info {
        position: absolute;
        text-align: left;
        top: 0;
        left: 0;
        z-index: 99;

        .adv-title {
          .medium-font();

          .tit {
            font-size: 32px;
            line-height: 64px;
            white-space: break-spaces;

            &.en {
              font-size: 48px;
              line-height: 64px;
            }
          }
        }

        .adv-title-small {
          margin-top: 9px;
          font-size: 24px !important;
          line-height: 64px !important;
        }

        .adv-title-small-en {
          margin-top: 18px;
          font-size: 30px !important;
          line-height: 44px !important;
          white-space: break-spaces;
        }

        .adv-subtitle {
          margin-top: 9px;
          line-height: 26px;
          font-size: 20px;
          display: flex;
          align-items: center;

          &.hide {
            display: none;
          }

          img {
            width: 26px;
            margin: 0 12px 0 0;
          }
        }

        .tg-link-wrap {
          display: inline-block;
          margin-top: 20px;
          cursor: pointer;
          padding: 0 16px;
          height: 40px;
          line-height: 40px;
          border-radius: 28px;
          border: 1px solid @yellow-P3;
          color: @text-black;
          font-size: 14px;
          .medium-font();
          .tg-icon {
            margin-top: -2px;
            width: 24px;
            height: 24px;
            margin-right: 8px;
            vertical-align: middle;
          }

          .tg-jiantou {
            margin-top: -2px;
            width: 14px;
            height: 14px;
            margin-left: 40px;
            vertical-align: middle;
          }
        }

        .transaction-steps {
          margin-top: 20px;

          &.en {
            margin-top: 32px;
          }

          .steps-1 {
            display: flex;
            align-items: center;

            .el-input {
              height: 50px;

              .el-input__inner {
                height: 50px;
                line-height: 50px;
                border-radius: 5px;
                color: @text-black;

                &:focus {
                  border: 1px solid @yellow-P3;
                  background: unset;
                  color: @text-black;
                }
              }
            }

            img {
              width: 40px;
              margin: 0 8px 0 0;
            }

            .steps-1-input {
              width: 310px;
              height: 50px;
            }
          }

          .steps-2,
          .steps-3 {
            display: flex;
            align-items: center;

            img {
              width: 40px;
              margin-right: 8px;
            }

            .steps-info {
              font-size: 14px;
              line-height: 18px;
              white-space: break-spaces;
              .medium-font();
            }

            .steps-info2 {
              font-size: 14px;
              line-height: 18px;
              .medium-font();
              color: @gray-P1-alpha20;
            }
          }

          .steps-2 {
            img {
              margin-left: 1px;
            }
          }

          .steps-3 {
            img {
              margin-left: 3px;
            }
          }

          .step-icon {
            width: 50px;
            height: 50px;
            background: @gray-P1-alpha10;
            border-radius: 5px;
            margin-right: 10px;
            cursor: pointer;

            &:hover {
              background: @gray-P1-alpha20;

              .qr {
                opacity: 1;
                transition: all 0.2s;
                visibility: visible;
              }
            }

            .icon-img {
              width: 36px;
              height: 36px;
              padding: 7px;
            }

            .qr {
              position: relative;
              top: -15px;
              color: @text-black;
              background: @theme-white;
              opacity: 0;
              visibility: hidden;
              transform: translateY(20px);
              border-radius: 9px;
              box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
              padding: 20px;

              // 下载
              &.download {
                right: 50px;
                padding-top: 21px;
                padding-bottom: 14px;
                width: 150px;
                box-sizing: border-box;
                border-radius: 9px;

                &>div {
                  // .flex();
                  // .flex-main-justify();
                  color: #fff;
                }

                .img-container {
                  img {
                    display: block;
                    width: 100px;
                    height: 100px;
                    margin-bottom: 10px;
                  }
                }

                .tit {
                  font-size: 14px;
                  .medium-font();
                  color: @text-black;
                  line-height: 18px;
                  text-align: center;
                  margin-top: 5px;
                }

                .sub-tit {
                  height: 17px;
                  font-size: 12px;
                  color: @gray-P1;
                  line-height: 15px;
                  text-align: center;
                  margin-top: 8px;
                }
              }
            }
          }

          .step-line-mod {
            width: 20px;
            height: 20px;
            margin-left: 6px;
            margin-bottom: 8px;

            &.line2 {
              margin-top: 6px;
            }
          }
        }
      }

      //吳宗憲代言人（spokesperson）
      .home-banner-img {
        .banner-img {
          width: 463px;
          height: 437px;
          position: absolute;
          top: -52px;
          left: 740px;
          background-image: url(~assets/img/new_index/bg_home_light.png);
          background-size: contain;
          background-repeat: no-repeat;
        }

        .spokesperson-big {
          .spokesperson-zhCN {
            //簡中
            width: 530px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 674px;
            background-image: url(~assets/img/new_index/bg_home_zhCN.png);
            background-size: contain;
            background-repeat: no-repeat;
          }

          .spokesperson-zhTW {
            //繁中
            width: 530px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 674px;
            background-image: url(~assets/img/new_index/bg_home_zhTW.png);
            background-size: contain;
            background-repeat: no-repeat;
          }

          .spokesperson-en {
            //英文
            width: 530px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 674px;
            background-image: url(~assets/img/new_index/bg_home_en.png);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .font-E6AD00 {
      color: #000000 !important;

      &:hover {
        color: @yellow-P3 !important;
      }
    }

    // 轮播的分页及显示箭头
    .home-show-swiper {
      display: block;
      width: 1200px;
      margin: 12px auto 0;
      text-align: right;
      color: #fff;
      overflow: hidden;

      // 箭头
      .arrow-show-swiper {
        .pr();
        z-index: 11;
        display: inline-block;
        transition: all 0.35s;
        background-color: #0c1e37;

        &.unfold {
          transform: rotate(180deg);
          transform-origin: center center;
        }

        &.collapse {
          transform: rotate(0deg);
          transform-origin: center center;
        }

        .icon-jiantoubeifen2 {
          font-size: 18px;
          cursor: pointer;
        }
      }
    }

    // 轮播的分页在此
    .swiper-paginationlong {
      position: absolute;
      bottom: -40px;
      right: 40px;
    }

    .swiper-paginationlong .swiper-pagination-bullet {
      width: 22px;
      height: 4px;
      margin-left: 8px;
      background-color: #fff;
      border-radius: 0;
      cursor: pointer;

      &.actie {
        background-color: #00a3ff;
      }
    }
  }

  .home-swiper {
    width: 1200px;
    height: 150px;
    margin: 0px auto 15px;
  }

  // 公告
  .home-notice {
    width: 1200px;
    height: 30px;
    margin: 0 auto 125px;
    .flex();
    justify-content: space-between;

    .notice-more {
      display: inline-block;
      width: auto;
      color: @gray-P1;
      line-height: 30px;
      .flex();
      align-items: center;
      cursor: pointer;
      .more-link{
        color: #fff;
      }
      &:hover {
        .more-link{
          color: @yellow-P3;
        }
        .icon-more {
          background: url(~assets/img/new_index/yellow_right_Hover.png) center no-repeat;
          background-size: 16px 16px;
        }
      }

      &::after {
        display: none;
      }

      >i {
        margin-left: 4px;
        font-size: 12px;
        transform: scale(0.9);
      }

      .icon-more {
        width: 14px;
        height: 14px;
        margin-left: 8px;
        background: url(~assets/img/new_index/gray_right_night.png) center no-repeat;
        background-size: 16px 16px;
      }
    }
  }

  .home-notice-item {
    width: 1000px;
    // margin: 0 auto;
    // padding: 0 130px;
    text-align: center;
    .flex();
    // .flex-cross-center();
    // .flex-main-center();
    //border: 1px solid green;

    >a {
      display: flex;
      align-items: center;
      max-width: 240px;
      color: #000000;
      clear: both;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.35s;

      &:hover {
        color: @yellow-P3;
      }

      >img {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }

      .notice-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }

    .notice-split {
      width: 50px;
      color: #d0d1d4;
      text-align: center;
      line-height: 30px;
    }

    .marquee_box {
      display: block;
      position: relative;
      width: 1000px;
      overflow: hidden;
    }

    .marquee_list {
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .marquee_top {
      transition: all 1s;
      margin-top: -30px;
    }

    .marquee_list li {
      line-height: 30px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      .title {
        width: 700px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        cursor: pointer;
        .medium-font();
        color: #fff;

        &:hover {
          color: @yellow-P3;
        }
      }
    }
  }

  .home-banner-box-en {
    // background: #181d2c url(~assets/img/public/bg_pc.png) no-repeat;
    background: #010101 url(~assets/img/public/PCbanner0-EN.jpg) center no-repeat;
    display: block;
    height: 311px;
    margin: -64px auto 0;
    padding-top: 64px;
    overflow: hidden;
    background-size: contain;
  }

  // 全屏背景色
  .home-screen-item {
    background-color: #fff;

    &.advantage {
      background-color: #fafafa;
      // border: 1px solid red;
    }

    &.reg {
      // height: 243px;
      //border: 1px solid blue;
      //height: 320px;
      //background: url('~assets/img/index/register_bg.png') center center no-repeat;
      //background-size: 100% 100%;
    }

    &.funding-support {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 108px;
        width: 950px;
      }
    }

    &.oversea {
      background: #061119;
    }
  }



  .font-link {
    cursor: pointer;
  }

  // .font-link:hover {
  //   color: @yellow-P3 !important;
  // }

  .business-introduction {
    background: #fafafa;
    // border: 1px solid green;

    .business-introduction-mod {
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 0;

      .title {
        margin: 0 auto;
        font-size: 36px;
        line-height: 50px;
        color: @text-black;
        text-align: center;
        .medium-font();
      }

      .business-mod {
        display: flex;
        flex-wrap: wrap;
        padding: 68px 0 70px;

        .business-item {
          width: 324px;
          padding: 32px 28px 31px;
          background: #ffffff;
          border-radius: 8px;
          margin-bottom: 30px;

          &:hover {
            box-shadow: 0px 8px 16px 0px rgba(24, 26, 32, 0.12);
          }

          .item-tit {
            height: 40px;
            line-height: 40px;
            font-size: 28px;
            .medium-font();
          }

          .item-tit-en {
            font-size: 24px;
          }

          .item-sub-tit {
            height: 22px;
            font-size: 16px;
            color: #474d57;
            line-height: 22px;
            margin-top: 14px;
          }
        }

        .m-mr {
          margin-right: 30px;
        }
      }
    }
  }

  .official-data-wrap {
    margin-top: 120px;
    padding-top: 97px;

    .official-data-mod {
      background: @black-module-color;

      .official-data-main {
        position: relative;
        width: 1200px;
        height: 180px;
        margin: auto;

        .official-img-wrap {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 356px;
        }

        .official-main {
          display: flex;
          justify-content: flex-end;
          column-gap: 64px;
          padding-top: 48px;
          box-sizing: border-box;
          width: 100%;
          height: 180px;

          .item-active {
            color: #fff;
            cursor: pointer;
            display: block;

            .right-arrow {
              display: inline-block;
              padding-right: 32px;
              height: 24px;
              line-height: 24px;
              background-image: url('~assets/img/common/right-arrow-white.png');
              background-size: 24px 24px;
              background-position: right center;
              background-repeat: no-repeat;
            }

            &:hover {
              color: @yellow-P2-new;

              .right-arrow {
                background-image: url('~assets/img/common/right-arrow.png');
              }
            }
          }

          .title {
            font-size: 32px;
            .bold-font();
            line-height: 48px;
            margin-bottom: 15px;
          }

          .desc {
            display: flex;
            font-size: 16px;
            align-items: center;

            .right-arrow {
              width: 24px;
              margin-left: 12px;
              display: none;
            }
          }
        }
      }

      &.white {
        background: @white-module-color;
      }
    }
  }

  .weex-school {
    // border: 1px solid blue;
    padding: 157px 0 170px 0;
    display: block;

    &.en {
      padding-top: 0px;
    }

    .weex-school-mod {
      width: 1200px;
      margin: 0 auto;
      width: 1200px;

      //border: 1px solid red;
      &.en {
        display: none;
      }

      .school-des {
        .flex();
        .flex-main-justify();
        margin: 0px auto 64px;

        .title {
          // margin: 170px auto 64px;
          font-size: 40px;
          height: 51px;
          line-height: 51px;
          color: #000000;
          text-align: left;
          .bold-font();
        }

        .more-school-item {
          float: right;
          font-size: 14px;
          // margin-top: 12px;
          .flex();
          align-items: center;
          color: @gray-P1;
          cursor: pointer;

          &:hover {
            color: @yellow-P3;

            .icon-more {
              background: url(~assets/img/new_index/icon_more_h.svg) center no-repeat;
            }
          }

          >i {
            margin-left: 4px;
            font-size: 12px;
            transform: scale(0.9);
          }

          .icon-more {
            width: 14px;
            height: 14px;
            margin-left: 8px;
            background: url(~assets/img/new_index/icon_more.svg) center no-repeat;
          }
        }
      }

      .school-mod {
        .flex();
        .flex-main-justify();
        // padding: 68px 0 0;

        .school-item {
          width: 375px;
          // height: 209px;
          position: relative;

          .play-icon {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 32px;
            height: 32px;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          video {
            // border-radius: 8px;
            height: 209px;
            background: #f9f9f9;
            border-radius: 9px;
          }

          p {
            margin-top: 24px;
            height: 25px;
            font-size: 18px;
            color: #000000;
            line-height: 25px;
            // margin-bottom: 170px;
          }

          // video:hover {
          //   background: rgba(0, 0, 0, 0.5);
          // }

          /* 隐藏video 所有控件 */

          video::-webkit-media-controls-enclosure {
            display: none;
          }
        }

        .m-mr {
          margin-right: 30px;
        }
      }
    }
  }

  .home-down-app {
    background: #ffffff;
  }

  .license-plate {
    background: #fafafa;

    .license-plate-mod {
      width: 1200px;
      margin: 0 auto;
      // border: 1px solid green;

      .title {
        padding: 80px 0;
        font-size: 36px;
        line-height: 50px;
        color: @text-black;
        text-align: center;
        .medium-font();
      }

      .plate-mod {
        display: flex;
        flex-wrap: wrap;
        padding: 83px 0 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .plate-item {
          padding-top: 7px;
          height: 175px;

          p {
            width: 411px;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 25px;
          }
        }

        .plate-item-r {
          display: flex;
          align-items: flex-end;
          margin-right: -22px;

          .item-info {
            margin: 0 22px;
          }

          .plate-name {
            height: 20px;
            line-height: 20px;
            margin-top: 12px;
            font-size: 14px;
            color: #474d57;
            text-align: center;
          }

          .plate-applying {
            margin-top: 0;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #474d57;
            text-align: center;
          }
        }
      }
    }
  }
}

.mobile-home-adv-box {
  display: none;
  width: auto;
  margin: -64px auto 0;
  padding-top: 64px;
  padding-bottom: 60px;
  .px2rem(padding-left, 40);
  .px2rem(padding-right, 40);
  overflow: hidden;
  background: #181d2c url(~assets/img/public/banner-0618.png) center center no-repeat;
  background-size: cover;

  .mobile-banner-h2 {
    width: auto;
    .px2rem(margin-top, 80);
    .px2rem(padding-left, 40);
    .px2px(font-size, 60);
    color: #fff;
    line-height: 1.2;
    font-weight: normal;
    // font-family: BlinkMacSystemFont, PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    // Hiragino Sans GB, Tahoma, sans-serif;
  }

  .mobile-banner-h1 {
    width: auto;
    margin-top: 16px;
    .px2rem(padding-left, 40);
    .px2px(font-size, 42);
    color: #9ba1a9;
    line-height: 1.2;
  }

  .mobile-home-swiper-box {
    width: 100%;
    max-width: 750px;
    .px2rem(margin-top, 80);
    .px2rem(margin-bottom, 80);

    .swiper-slide {
      width: 100%;
      height: 200px;

      a {
        width: 100%;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .mobile-swiper-paginationlong {
    display: block;
    width: 100%;
    margin: 10px auto 0;
    text-align: center;
  }

  .mobile-swiper-paginationlong .swiper-pagination-bullet {
    width: 22px;
    height: 4px;
    margin-left: 8px;
    background-color: #fff;
    border-radius: 0;
    cursor: pointer;

    &.actie {
      background-color: #00a3ff;
    }
  }

  .mobile-banner-otc {
    margin-top: 48px;

    // 非中文下，显示交易(合约/币币)按钮
    .mobile-banner-trade {
      >button {
        display: inline-block;
        width: 120px;
        height: 44px;
        color: #fff;
        text-align: center;
        .px2px(font-size, 32);
        background-color: #1465ff;
        border: 1px solid #1465ff;
        cursor: pointer;
        border-radius: 2px;
        transition: all 0.35s;

        &.default {
          margin-left: 12px;
          background-color: transparent;
          border: 1px solid #697281;
          color: #fff;

          &:hover {
            background-image: none;
            border-color: #1465ff;
            color: #1465ff;
          }
        }

        &:hover {
          transition: all 0.35s;
          background-image: linear-gradient(180deg, #1499ff 0%, #1465ff 100%);
        }
      }
    }
  }
}

.mobile-home-coin-wrap {
  display: none;
  .px2rem(margin-top, 40);

  // 合约切换
  .mobile-coin-tab {
    .flex();
    .flex-cross-center();
    padding: 0 10px 13px;

    span {
      display: inline-block;
      height: 28px;
      margin-right: 46px;
      margin-bottom: -14px;
      padding: 28px 0 10px;
      font-size: 18px;
      line-height: 26px;
      color: @gray-P1;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: @text-black;
        border-color: #1465ff;
      }
    }
  }

  .mobile-coin-split {
    width: 100%;
    height: 1px;
    margin: 0 auto;
    background-color: #e6ebef;
  }

  .mobile-coin-show-more {
    .px2rem(margin-top, 30);
    text-align: center;

    span {
      .px2px(font-size, 28);
      color: @gray-P1;
      cursor: pointer;
    }
  }

  .mobile-coin-item-box {
    .flex();
    .flex-cross-center();
    .px2rem(margin-top, 40);
    .px2rem(padding-left, 50);
    .px2rem(padding-right, 50);
    color: @text-black;

    i,
    em {
      font-style: normal;
    }

    .mobile-coin-list-first {
      flex: 1;

      div:first-child {
        .px2px(font-size, 36);

        em {
          .px2px(font-size, 24);
          color: @gray-P1;
        }
      }

      div:nth-of-type(2) {
        .px2px(font-size, 28);
        color: @gray-P1;
      }
    }

    .mobile-coin-list-second {
      flex: 1;

      div:first-child {
        .px2px(font-size, 36);
      }

      div:nth-of-type(2) {
        .px2px(font-size, 28);
        color: @gray-P1;
      }
    }

    .mobile-coin-list-three {
      flex: 0.8;
      text-align: center;

      .mobile-coin-list-float {
        display: inline-block;
        min-width: 56px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        text-align: center;
        .px2px(font-szie, 32);
        color: #697281;
        background-color: #f3f4f4;
        border-radius: 2px;

        // 涨
        &.rise {
          color: @green-color;
          background-color: #f3fbf8;
        }

        // 跌
        &.fall {
          color: @red-color;
          background-color: #fff8f8;
        }
      }
    }
  }
}

.theme-white.home-box {
  .home-coin .coin-list>span .btn-transaction {
    background: @white-btn-line;
  }

  .home-coin .coin-list:hover {
    background: @white-card-bg;

    >span .btn-transaction {
      background: @yellow-P1;
    }
  }
}

.theme-black.home-box {
  color: #ffffff;
  background: @theme-black-bg;

  .home-adv-box .home-adv-wrap .home-adv-info .tg-link-wrap {
    color: @text-white;
  }

  .home-adv-box {
    display: block;
    height: 462px;
    padding: 50px 0 50px;
    overflow: hidden;
    //background: #1E1E1E url(~assets/img/new_index/bg_black.png) center center no-repeat;
    background: @theme-black-bg;
    background-size: cover;
    text-align: center;
    min-width: 1280px;

    &.en {
      padding-bottom: 100px;
    }

    .font-E6AD00 {
      color: #ffffff !important;

      &:hover {
        color: @yellow-P3 !important;
      }
    }

    .home-adv-wrap .home-adv-info .transaction-steps .steps-1 .el-input .el-input__inner {
      color: @text-white;

      &:focus {
        background: unset !important;
        color: @text-white;
      }
    }

    .home-adv-wrap .home-adv-info .transaction-steps .step-icon .qr {
      background: @theme-black-P3;
      color: @text-white;
      box-shadow: 0px 3px 30px 0px rgb(0 0 0 / 10%);

      .tit {
        color: @text-white;
      }
    }

    .home-adv-wrap .home-banner-img .banner-img {
      background-image: url(~assets/img/new_index/bg_home_dark.png);
      //background-image: url(~assets/img/new_index/bg_home.png);
    }
  }

  .home-notice-item>a {
    color: #eaecef;

    &:hover {
      color: @yellow-P3;
    }
  }

  .notice-more {
    color: @gray-P1;
  }

  .home-screen-item {
    background-color: @theme-black-bg;
  }

  .home-coin {
    background: none;
    box-shadow: none;
  }

  .home-coin .title {
    color: #ffffff;
  }

  .home-coin .coin-tab span.active {
    color: #ffffff;
  }

  .home-coin .coin-tab span {
    color: @gray-P1;
  }

  .home-coin .coin-list-title {
    color: @gray-P1;
  }

  .home-coin .coin-list {
    color: #ffffff;
  }

  .home-coin .coin-list>span .coin-list-float.fall {
    background: transparent !important;
  }

  .home-coin .coin-list>span .coin-list-float.rise {
    background: transparent !important;
  }

  .home-coin .coin-list:hover {
    background: #191b20;
    border-radius: 8px;

    >span .btn-transaction {
      background: @yellow-P1-new;
      color: #000000;
    }
  }

  .business-introduction .business-introduction-mod .title {
    color: #eaecef;
  }

  .business-introduction .business-introduction-mod .business-mod .business-item {
    background: #282929;

    &:hover {
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.24);
    }
  }

  .business-introduction .business-introduction-mod .business-mod .business-item .item-sub-tit {
    color: #b7bdc6;
  }

  .weex-school .weex-school-mod .title {
    color: #ffffff;
  }

  .weex-school .weex-school-mod .school-mod .school-item {
    video {
      background: #202228;
    }

    p {
      color: #ffffff;
    }
  }

  .license-plate .license-plate-mod .title {
    color: #eaecef;
  }

  .license-plate .license-plate-mod .plate-mod .plate-item-r .plate-name {
    color: #b7bdc6;
  }

  .home-coin .coin-list>span .coin-list-float {
    background: transparent;
  }

  .home-coin .coin-list>span .btn-transaction:hover {
    color: #000000;
    border-color: @yellow-P2;
  }

  .home-coin .coin-list>span .btn-transaction {
    color: #ffffff;

    // border-color: #D8D8D8;
    >span {
      line-height: 32px;
      height: 32px;
    }
  }

  .home-coin .coin-list>span:nth-of-type(1) em {
    color: @gray-P1;
  }

  .home-activity {
    background: #292929;
    color: #eaecef;
  }

  .home-coin .coin-show-more {
    color: @gray-P1;

    &:hover {
      color: @yellow-P3;
    }
  }
}

.copy-trade {
  width: 44px;
  height: 44px;
  background-image: url(~assets/img/new_index/icon_copy_trade.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 114px;
  right: 22px;
  justify-content: center;
  align-items: center;
  display: block;
  z-index: 99;
  cursor: pointer;

  &:hover {
    background-image: url(~assets/img/new_index/icon_copy_trade_h.svg);
  }

  // display: none;
}

.icon-go-top {
  width: 44px;
  height: 44px;
  background-image: url(~assets/img/new_index/icon_top.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 188px;
  right: 22px;
  justify-content: center;
  align-items: center;
  display: block;
  z-index: 99;
  cursor: pointer;

  &:hover {
    background-image: url(~assets/img/new_index/icon_top_h.svg);
  }

  // display: none;
}



@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation-name: FadeIn;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 0.5s;
}
</style>
